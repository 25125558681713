.warn {
    color: #d32f2f;
}

.primary {
    color: #94ba3f;
}

.accent {
    color: #ff9600;
}

body {
    margin: 0;
}

.mat-drawer {
    width: 200px;
}

html,
body {
    height: 100%;
}

.card-group {
    margin-bottom: 10px;
}

.mat-toolbar.mat-primary .mat-select-value {
    color: white;
    font-size: 14px;
}
.mat-toolbar.mat-primary .mat-select-arrow-wrapper {
    color: white;
}

.mat-toolbar.mat-primary .mat-paginator {
    background-color: #94ba3f;
    color: white;
    font-size: 16px;
}
@media screen and (max-width: 550px) {
    .mat-paginator-range-actions {
        flex-wrap: wrap-reverse;
    }
    .mat-paginator-range-label {
        width: 100%;
        flex: 1fr;
    }

    .mat-paginator-page-size {
        flex-wrap: wrap;
    }
}

.no-pad .mat-button-wrapper {
    padding: 0 !important;
}

@import './base.theme.scss';
@import url('https://fonts.googleapis.com/css?family=Work+Sans:300,400,500');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700');



$custom-typography: mat-typography-config(
  $font-family: "'Montserrat'",
  $body-2:        mat-typography-level(14px, 24px, 500),
  $body-1:        mat-typography-level(14px, 20px, 400),
  $caption:       mat-typography-level(12px, 20px, 400),
  $button:        mat-typography-level(14px, 14px, 500),
  // Line-height must be unit-less fraction of the font-size.
  $input: mat-typography-level(inherit, 1.125, 400)
);

h1, h2, h3, h4, h5, h6 {
    font-family: 'Work Sans';
}


@include mat-base-typography($custom-typography);
@include angular-material-typography($custom-typography);
@include mat-core($custom-typography);

@include angular-material-theme($fixiti-app-theme);

.fixiti-no-padding-dialog .mat-dialog-container {
    padding: 0;
}
